/*@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Roboto&display=swap');*/

@import url("https://fonts.googleapis.com/css2?family=Fira+Sans&display=swap");

/* variables */
:root {
  /* --heading-color: #444;
  --text-color: #999;
  --primary-color: #8d69f1;*/
  --highlight-color: #d13267;
  /* --bg-color: #f4f4f4;  */
  --primary-color: #58249c;
}

/* base styles */
html,
body,
#root {
  height: 100%;
}
body {
  font-family: fira-sans, sans-serif;
  margin: 0;
  font-size: 1em;
  background: #dfdfdf;
  /* color: #333; */
  color: #fff;
}
h1,
h2,
h3,
p {
  margin: 0;
}
input,
textarea {
  color: #333;
  padding: 8px;
  font-size: 1em;
  border-radius: 4px;
  border: 1px solid #d4d4d4;
  background-color: #fff;
  display: block;
  width: 100%;
  box-sizing: border-box;
}
.page-title {
  text-align: center;
  margin: 40px auto 10px auto;
  color: #e4e4e4;
  /* font-size: 1.8em; */
  /* font-weight: bold; */
}
.loading,
.error {
  text-align: center;
  margin: 40px auto;
}

.btn {
  background: #fff;
  /* padding: 8px 12px; */
  border-radius: 4px;
  color: var(--primary-color);
  cursor: pointer;
  font-size: 1em;
  border: 1px solid var(--primary-color);
}
.btn:hover {
  color: #fff;
  background-color: var(--primary-color);
}

/* forms */
/* label {
  display: block;
  margin: 10px auto;
} */

/*List styling basis for all page lists*/
.list {
  display: grid;
  grid-template-columns: 1fr;
  /*grid-template-columns: 1fr 1fr 1fr;*/
  grid-gap: 10px;
  max-width: 1200px;
  margin: 40px auto;
}
.list .header {
  display: grid;
  /*grid-template-columns: 1fr 1fr 1fr 0.5fr 0.5fr 0.5fr;*/
  color: #fff;
  font-size: 1em;

  padding: 15px;
}
.list .card {
  /* display: grid; */
  /*grid-template-columns: 1fr 1fr 1fr 0.5fr 0.5fr 0.5fr;*/

  background: #fff;
  color: #333;
  padding: 15px;
  border-radius: 4px;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  position: relative;
}

.list .card h3 {
  color: #555;
  margin-bottom: 6px;
}
.list .card p,
.list .card ul {
  color: #999;
  font-size: 1em;

  word-wrap: break-word;
  word-break: break-all;
}
.list .card div {
  color: #555;
  font-size: 0.7em;
  margin: 20px 0;
  line-height: 1.5em;
}
.list .card a {
  color: #555;
  text-decoration: none;
  display: block;
  background: #e2e2e2;
  font-size: 1em;
  width: 100px;
  text-align: center;
  width: 120px;
  padding: 8px;
  border-radius: 4px;
  margin: 20px auto 0;
}

/* dark mode */
.list .card.dark {
  background: #555;
}
.list .card.dark p,
.list .card.dark h3,
.list .card.dark div,
.list .card.dark ul,
.list .card.dark ol {
  color: #e4e4e4;
}
/*End list styling*/

.form {
  display: grid;
  grid-template-columns: 1fr;
  /*grid-template-columns: 1fr 1fr 1fr;*/
  grid-gap: 10px;
  max-width: 1200px;
  margin: 40px auto;
  color: #e4e4e4;
}
