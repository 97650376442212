 .recipe {
    max-width: 900px;
    margin: 0px auto;
    text-align: center;
    /* background: #fff; */
    /* padding: 40px; */
    /* box-sizing: border-box; */
  }
.recipe ul {
    /* display: flex; */
    padding: 0;
    justify-content: center;
    margin-top: 0;
  }
  .recipe li {
    list-style-type: none;
    margin-right: 10px;
    color: #e4e4e4;
  }
   /* .recipe li::after {
    content: ",";
  }
  .recipe li:last-child::after {
    content: ".";
  }
  .method {
    text-align: left;
    line-height: 1.5em;
  } */