.recipes-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 40px;
    max-width: 1200px;
    margin: 40px auto;
  }

  @media screen and (max-width: 900px) {
    .recipes-list {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 40px;
      max-width: 1200px;
      margin: 40px auto;
    }
  }