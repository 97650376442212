.App {
  min-height: 100%;
}
.App.dark {
  background: #333;
}
.Container {
  padding: 0px .2em;
  max-width: 1200px;
  margin: 40px auto;
}